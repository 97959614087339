var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"o-accordion-menu services"},[_c('div',{staticClass:"services__list"},[_c('div',{staticClass:"services__list--title"},[_vm._m(0),_c('h3',[_vm._v(_vm._s(_vm.$t("Ce asigurare te interesează?")))])]),_c('div',{staticClass:"services__list--content"},_vm._l((_vm.menu),function(category,i){return _c('div',{key:i,staticClass:"accordion"},[_c('div',{staticClass:"services__list--content-item",class:{
            'services__list--content-item_active':
              category.slug == _vm.selectedCategory,
          },on:{"click":function($event){return _vm.setCategory(category.slug)}}},[_c('div',{staticClass:"list-item__icon"},[_c('img',{attrs:{"src":category.icon
                  ? category.icon
                  : require('@/assets/icons/right-arrow.png')}})]),_c('span',[_vm._v(_vm._s(_vm.$t(category.name)))])]),_c('div',{staticClass:"accordion-menu",class:{
            'accordion-menu__active': category.slug == _vm.selectedCategory,
          }},_vm._l((category.children),function(item,j){return _c('div',{key:j,staticClass:"accordion-menu__item",on:{"click":function($event){return _vm.setMenu(item)}}},[_vm._m(1,true),_c('span',{class:{
                'accordion-menu__item--active':
                  item.slug == _vm.selectedMenu.slug,
              }},[_vm._v(_vm._s(_vm.$t(item.title)))])])}),0)])}),0)]),(_vm.selectedMenu)?_c('div',{staticClass:"services__details"},[_c('div',{staticClass:"services__details--info"},[_c('h2',[_vm._v(_vm._s(_vm.$t(_vm.selectedMenu.title)))]),_vm._l((_vm.selectedMenu.data),function(data,i){return _c('div',{key:i,staticClass:"menuData"},[(data.type == 'subtitle')?_c('h4',[_vm._v(_vm._s(_vm.$t(data.content)))]):_vm._e(),(data.type == 'description')?_c('p',[_vm._v(_vm._s(_vm.$t(data.content)))]):_vm._e(),(data.type == 'list')?_c('ul',_vm._l((data.content),function(item,j){return _c('li',{key:j},[_vm._v(" "+_vm._s(_vm.$t(item))+" ")])}),0):_vm._e(),(data.type == 'image')?_c('div',{staticClass:"dataImage"},[_c('img',{attrs:{"src":data.content}})]):_vm._e(),(data.type == 'note')?_c('span',[_vm._v(_vm._s(_vm.$t(data.content)))]):_vm._e()])})],2)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"services__list--title-icon"},[_c('img',{attrs:{"src":require("@/assets/icons/information.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accordion-menu__item--icon"},[_c('img',{attrs:{"src":require("@/assets/icons/right-arrow.png")}})])
}]

export { render, staticRenderFns }