<template>
  <div class="personal-insurance page">
    <!-- <div class="insurance-section">
			<MSectionHeader class="header" title="Health in Germany" />
			<div class="products">
				<MProductCard
					v-for="(insurance, i) in healthInsurances"
					:key="i"
					:product="insurance"
					class="products__card"
				/>
			</div>
		</div> -->
    <section class="insurance-section" ref="#personal-insurance">
      <MSectionHeader class="header" :title="$t('Asigurări Private')" />
      <OAccordionMenu :menu="personalInsurances" />
    </section>
    <section class="insurance-section" ref="#business-insurance">
      <MSectionHeader
        class="header"
        :title="$t('Asigurări Firmă')"
        :description="companyInsurancesDescription"
      />
      <div class="companyAdvantages">
        <div class="companyAdvantages__cover companyAdvantages__section">
          <img src="@/assets/images/handshake_sm.jpg" />
        </div>
        <div class="companyPerks__container companyAdvantages__section">
          <div class="companyPerks__title">
            <h4>{{ $t("Avantaje") }}</h4>
          </div>
          <div class="companyPerks">
            <MPerk
              v-for="(perk, i) in perks"
              :key="i"
              :perk="perk"
              class="companyPerks__perk"
            />
          </div>
          <span class="companyPerks__sidenote">{{
            $t(
              "* Daune de până la 20% din suma asigurată până la maximum 1 milion de euro"
            )
          }}</span>
        </div>
      </div>
      <div class="companyInsurances__container">
        <div class="companyPerks__title">
          <h4>{{ $t("Ce Include") }}</h4>
        </div>
        <SfCarousel
          class="m-product-carousel"
          :settings="carouselSettings"
          :key="companyInsurances.length"
          :defaultColor="true"
        >
          <SfCarouselItem v-for="(card, i) in companyInsurances" :key="i">
            <MInfoCardVertical
              :product="card"
              class="companyInsurances__card"
            />
          </SfCarouselItem>
        </SfCarousel>
        <!-- <div class="companyInsurances">
					<MInfoCardVertical
						v-for="(card, i) in companyInsurances"
						:key="i"
						:product="card"
						class="companyInsurances__card"
					/>
				</div> -->
      </div>
      <div class="companyAdvantages">
        <div class="companyPerks__container companyAdvantages__section">
          <div class="companyPerks__title">
            <h4>{{ $t("Bonusuri și Discount-uri") }}</h4>
          </div>
          <div class="companyPerks">
            <MPerk
              v-for="(perk, i) in bonuses"
              :key="i"
              :perk="perk"
              class="companyPerks__perk"
            />
          </div>
        </div>
        <div class="companyAdvantages__cover companyAdvantages__section">
          <img src="@/assets/images/bonuses_sm.jpg" />
        </div>
      </div>
      <div class="generalInfo">
        <MInfoCarousel :info="basicInfo" />
      </div>
    </section>
  </div>
</template>
<script>
import MSectionHeader from "@/components/molecules/m-section-header";
// import MProductCard from '@/components/molecules/m-product-card'
import OAccordionMenu from "@/components/organisms/o-accordion-menu";
import MPerk from "@/components/molecules/m-perk";
import MInfoCarousel from "@/components/molecules/m-info-carousel";
import MInfoCardVertical from "@/components/molecules/m-info-card-vertical";
import { SfCarousel } from "@storefront-ui/vue";

export default {
  components: {
    MSectionHeader,
    // MProductCard,
    OAccordionMenu,
    MPerk,
    MInfoCarousel,
    MInfoCardVertical,
    SfCarousel,
  },
  data() {
    return {
      carouselSettings: {
        animationDuration: 400,
        rewind: true,
        slidePerPage: false,
        perView: 4,
        peek: {
          before: 0,
          after: 40,
        },
        breakpoints: {
          768: {
            perView: 1,
            peek: {
              before: 30,
              after: 30,
            },
          },
        },
      },
      companyInsurancesDescription:
        "Ne dedicăm protejării afacerii dumneavoastră și a celor mai valoroși activi ai săi: angajații. Oferim o gamă completă de soluții de asigurare, adaptate pentru a satisface nevoile specifice ale fiecărei afaceri, de la start-up-uri la corporații multinaționale.",
      personalInsurances: [
        {
          name: "Prevenție",
          slug: "preventie",
          icon: require("@/assets/icons/health-care.png"),
          children: [
            {
              title: "Asigurarea de Prevenție BU",
              slug: "asigurarea-de-preventie-bu",
              data: [
                {
                  type: "subtitle",
                  content: "Ce este Asigurarea de Prevenție BU?",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea de prevenție BU (Betriebsunterbrechungsversicherung) este o poliță de asigurare destinată protejării afacerilor împotriva pierderilor financiare cauzate de întreruperea activității. Aceasta se activează în cazul unor evenimente neprevăzute, cum ar fi incendii, inundații sau alte dezastre, care afectează bunurile asigurate și impun suspendarea temporară a operațiunilor.",
                },
                {
                  type: "description",
                  content:
                    "Prin intermediul asigurării de prevenție BU, companiile primesc compensații care acoperă pierderile de venituri și cheltuielile fixe necesare pentru menținerea stabilității financiare pe durata întreruperii activității. Astfel, firmele pot face față mai ușor perioadelor de inactivitate și pot reveni rapid la capacitatea operațională normală.",
                },
                {
                  type: "subtitle",
                  content: "Beneficiile Asigurării de Prevenție BU",
                },
                {
                  type: "description",
                  content:
                    "Această asigurare oferă compensații pentru pierderile de venituri și costurile fixe pe care compania le suportă în timpul perioadei de inactivitate. Beneficiile specifice ale asigurării de prevenție BU includ:",
                },
                {
                  type: "list",
                  content: [
                    "Acoperirea pierderilor de venituri: Compania primește despăgubiri pentru veniturile pierdute în timpul întreruperii activității, asigurând un flux de numerar constant.",
                    "Acoperirea costurilor fixe: Polița acoperă cheltuielile fixe, cum ar fi salariile, chiria și utilitățile, care continuă să fie suportate chiar și atunci când afacerea este inactivă.",
                    "Acoperirea cheltuielilor suplimentare: Asigurarea poate include și costurile suplimentare necesare pentru a reduce perioada de întrerupere și a relua activitatea cât mai rapid posibil.",
                  ],
                },
                {
                  type: "description",
                  content:
                    "Prin aceste beneficii, asigurarea de prevenție BU ajută companiile să minimizeze impactul financiar al întreruperilor neprevăzute și să asigure continuitatea operațională.",
                },
                {
                  type: "subtitle",
                  content: "De ce este importantă Asigurarea de Prevenție BU?",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea de prevenție BU este esențială pentru a menține stabilitatea financiară a afacerii în caz de incidente neprevăzute care pot duce la întreruperea activității. Fără această protecție, companiile riscă să se confrunte cu dificultăți financiare majore, care pot duce chiar la faliment.",
                },
                {
                  type: "description",
                  content:
                    "Această asigurare nu doar că oferă siguranță financiară în perioadele de criză, dar și contribuie la planificarea pe termen lung a afacerii, oferindu-le proprietarilor de afaceri liniștea necesară pentru a se concentra pe dezvoltarea și extinderea companiei.",
                },
                {
                  type: "note",
                  content:
                    "Asigurarea de prevenție BU este un instrument vital pentru orice afacere care dorește să își protejeze investițiile și să asigure continuitatea operațională pe termen lung.",
                },
              ],
            },
            {
              title: "Asigurarea de Prevenție GF",
              slug: "asigurarea-de-preventie-gf",
              data: [
                {
                  type: "subtitle",
                  content: "Ce este Asigurarea de Prevenție GF?",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea de prevenție GF (Geschäftsführerausfallversicherung) este o poliță de asigurare destinată protejării afacerilor împotriva pierderilor financiare cauzate de incapacitatea temporară sau permanentă a administratorilor sau directorilor de a-și îndeplini atribuțiile. Aceasta se activează în cazul în care persoanele cheie din conducerea firmei sunt incapabile să-și desfășoare activitatea din motive de sănătate sau alte circumstanțe neprevăzute.",
                },
                {
                  type: "description",
                  content:
                    "Prin intermediul asigurării de prevenție GF, companiile primesc compensații pentru pierderile de venituri și cheltuielile suplimentare asociate cu găsirea și angajarea temporară a unui înlocuitor pentru persoanele cheie afectate. Astfel, firmele pot menține continuitatea operațională și pot minimiza impactul negativ asupra afacerii.",
                },
                {
                  type: "subtitle",
                  content: "Beneficiile Asigurării de Prevenție GF",
                },
                {
                  type: "description",
                  content:
                    "Această asigurare oferă compensații pentru pierderile de venituri și costurile suplimentare pe care compania le suportă în cazul incapacității temporare sau permanente a administratorilor. Beneficiile specifice ale asigurării de prevenție GF includ:",
                },
                {
                  type: "list",
                  content: [
                    "Acoperirea pierderilor de venituri: Compania primește despăgubiri pentru veniturile pierdute din cauza incapacității persoanelor cheie de a-și îndeplini atribuțiile.",
                    "Acoperirea costurilor pentru înlocuitori: Polița acoperă cheltuielile asociate cu găsirea și angajarea unui înlocuitor temporar pentru persoanele cheie afectate.",
                    "Acoperirea cheltuielilor suplimentare: Asigurarea poate include și costurile suplimentare necesare pentru a asigura continuitatea operațională în absența persoanelor cheie.",
                  ],
                },
                {
                  type: "description",
                  content:
                    "Prin aceste beneficii, asigurarea de prevenție GF ajută companiile să minimizeze impactul financiar al absenței persoanelor cheie și să asigure continuitatea operațională.",
                },
                {
                  type: "subtitle",
                  content: "De ce este importantă Asigurarea de Prevenție GF?",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea de prevenție GF este esențială pentru a menține stabilitatea financiară și operațională a afacerii în caz de incidente neprevăzute care afectează capacitatea de lucru a persoanelor cheie. Fără această protecție, companiile riscă să se confrunte cu dificultăți majore în gestionarea activităților zilnice și în menținerea stabilității pe termen lung.",
                },
                {
                  type: "description",
                  content:
                    "Această asigurare nu doar că oferă siguranță financiară în perioadele de criză, dar și contribuie la planificarea pe termen lung a afacerii, oferindu-le proprietarilor de afaceri liniștea necesară pentru a se concentra pe dezvoltarea și extinderea companiei.",
                },
                {
                  type: "note",
                  content:
                    "Asigurarea de prevenție GF este un instrument vital pentru orice afacere care dorește să își protejeze investițiile și să asigure continuitatea operațională pe termen lung, chiar și în absența persoanelor cheie.",
                },
              ],
            },
            {
              title: "Asigurarea de Viață",
              slug: "asigurarea-de-viata",
              data: [
                {
                  type: "subtitle",
                  content: "Ce este Asigurarea de Viață?",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea de viață este o poliță care oferă protecție financiară familiei și urmașilor în cazul decesului persoanei asigurate. Aceasta este o modalitate esențială de a asigura siguranța și stabilitatea financiară a celor dragi în absența ta.",
                },
                {
                  type: "description",
                  content:
                    "În cazul decesului persoanei asigurate, indiferent de motivul decesului, asigurarea de viață plătește suma asigurată beneficiarilor desemnați. Acest sprijin financiar poate acoperi cheltuielile de înmormântare, datoriile restante și poate asigura un venit suplimentar pentru a ajuta familia să se mențină pe linia de plutire.",
                },
                {
                  type: "subtitle",
                  content: "Beneficiile Asigurării de Viață",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea de viață oferă o serie de beneficii esențiale care contribuie la siguranța și stabilitatea financiară a familiei în cazul unui eveniment nefericit. Aceste beneficii includ:",
                },
                {
                  type: "list",
                  content: [
                    "Protecția financiară a familiei: Asigurarea plătește suma asigurată beneficiarilor desemnați, oferind un sprijin financiar crucial pentru cei dragi lăsați în urmă.",
                    "Acoperirea cheltuielilor de înmormântare: Polița poate acoperi costurile legate de înmormântare și alte cheltuieli finale, reducând povara financiară asupra familiei.",
                    "Asigurarea urmașilor: În cazul decesului persoanei asigurate, polița asigură un venit suplimentar pentru urmași, contribuind la menținerea stilului de viață al acestora.",
                    "Acoperirea datoriilor: Asigurarea de viață poate ajuta la achitarea datoriilor restante, cum ar fi ipotecile și împrumuturile, protejând astfel bunurile familiei.",
                  ],
                },
                {
                  type: "description",
                  content:
                    "Prin intermediul acestor beneficii, asigurarea de viață oferă liniște sufletească, știind că familia ta va fi protejată financiar în cazul unui eveniment tragic.",
                },
                {
                  type: "subtitle",
                  content: "De ce este importantă Asigurarea de Viață?",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea de viață este esențială pentru a asigura protecția financiară a familiei tale în absența ta. Este un element vital al planificării financiare pe termen lung, oferind siguranță și stabilitate celor dragi.",
                },
                {
                  type: "description",
                  content:
                    "Această asigurare nu doar că oferă sprijin financiar imediat în cazul decesului, dar și contribuie la acoperirea datoriilor și la menținerea stilului de viață al familiei tale. Asigurarea de viață este o investiție în siguranța și bunăstarea celor care contează cel mai mult pentru tine.",
                },
                {
                  type: "note",
                  content:
                    "Asigurarea de viață este un instrument indispensabil pentru oricine dorește să își protejeze familia și să asigure continuitatea financiară în cazul unui eveniment tragic.",
                },
              ],
            },
            {
              title: "Planul meu de viitor",
              slug: "planul-meu-de-viitor",
              data: [
                {
                  type: "subtitle",
                  content: "Începe să investești în viitorul tău",
                },
                {
                  type: "description",
                  content:
                    "Începe să investești în viitorul tău și al copiilor tăi astăzi, cu un cont de economii pe termen lung care îți oferă toate aceste avantaje și mai mult.",
                },
                {
                  type: "list",
                  content: [
                    "Stabilitate financiară pentru viitorul tău și al copiilor tăi.",
                    "Posibilitatea de a susține cheltuielile private și cele pentru educația copiilor și alte oportunități.",
                    "Siguranță financiară pentru momentele neprevăzute sau pentru atingerea unor obiective importante.",
                    "Creșterea valorii economisirii pe termen lung, care îți poate aduce beneficii semnificative în timp.",
                  ],
                },
                {
                  type: "description",
                  content:
                    "Planul meu de viitor este o asigurare de prevenție care îți permite să îți asiguri un viitor stabil și prosper pentru tine și pentru cei dragi. Prin economisirea regulată și investițiile pe termen lung, acest plan îți oferă securitatea financiară de care ai nevoie pentru a face față provocărilor și a profita de oportunitățile viitoare.",
                },
                {
                  type: "note",
                  content:
                    "Asigurarea de Prevenție: Planul meu de viitor este ideal pentru cei care doresc să își asigure un viitor financiar stabil și prosper, investind în siguranța și bunăstarea familiei lor.",
                },
                {
                  type: "subtitle",
                  content:
                    "Beneficiile Asigurării de Prevenție: Planul meu de viitor",
                },
                {
                  type: "description",
                  content:
                    "Această asigurare oferă multiple beneficii care contribuie la bunăstarea financiară pe termen lung. Printre acestea se numără:",
                },
                {
                  type: "list",
                  content: [
                    "Stabilitate financiară: Economisirea pe termen lung asigură o bază solidă pentru viitorul tău financiar.",
                    "Susținerea cheltuielilor educaționale: Planul îți permite să economisești pentru cheltuielile educaționale ale copiilor, asigurându-le accesul la cele mai bune oportunități de învățare.",
                    "Siguranță în momente neprevăzute: Fondurile economisite pot fi folosite pentru a acoperi cheltuielile neprevăzute sau pentru a atinge obiective importante în viață.",
                    "Creșterea valorii economisirii: Investițiile pe termen lung pot aduce randamente semnificative, sporind valoarea economiilor tale.",
                    "Flexibilitate financiară: Poți ajusta contribuțiile la plan în funcție de nevoile și obiectivele tale financiare.",
                  ],
                },
                {
                  type: "description",
                  content:
                    "Prin aceste beneficii, Asigurarea de Prevenție: Planul meu de viitor te ajută să îți construiești un viitor sigur și stabil, oferindu-ți liniștea sufletească că ești pregătit pentru orice provocare.",
                },
                {
                  type: "subtitle",
                  content: "De ce să alegi Planul meu de viitor?",
                },
                {
                  type: "description",
                  content:
                    "Alegând această asigurare, îți asiguri nu doar viitorul tău, ci și pe cel al copiilor tăi. Este un pas important în planificarea financiară pe termen lung, care îți oferă multiple avantaje și oportunități de creștere.",
                },
                {
                  type: "description",
                  content:
                    "Planul meu de viitor este conceput pentru a oferi flexibilitate și siguranță, permițându-ți să economisești într-un mod structurat și eficient. Acesta este un instrument esențial pentru oricine dorește să își protejeze și să își crească averea pe termen lung.",
                },
                {
                  type: "note",
                  content:
                    "Investind în acest plan, faci un pas esențial către un viitor sigur, pregătit pentru orice provocare și plin de oportunități.",
                },
              ],
            },
          ],
        },
        {
          name: "Sănătate",
          slug: "sanatate",
          icon: require("@/assets/icons/shield-2.png"),
          children: [
            {
              title: "Asigurarea de sănătate obligatorie de stat",
              slug: "asigurarea-de-sanatate-obligatorie",
              data: [
                {
                  type: "subtitle",
                  content:
                    "Ce este Asigurarea de Sănătate Obligatorie de Stat?",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea de sănătate obligatorie de stat este un sistem de protecție socială care asigură accesul tuturor cetățenilor la servicii medicale esențiale. Aceasta include diverse beneficii și servicii care contribuie la menținerea și îmbunătățirea stării de sănătate a populației.",
                },
                {
                  type: "subtitle",
                  content: "Sistemul de bonus Fit cu rambursare",
                },
                {
                  type: "description",
                  content:
                    "Sistemul de bonus Fit oferă rambursări pentru comportamente sănătoase și activități fizice. Acesta încurajează cetățenii să adopte un stil de viață activ și să participe la programe de fitness, oferind recompense financiare pentru atingerea anumitor obiective de sănătate.",
                },
                {
                  type: "note",
                  content:
                    "Este esențial să fii informat despre beneficiile asigurării de sănătate obligatorii pentru a putea profita la maximum de serviciile disponibile.",
                },
                {
                  type: "subtitle",
                  content:
                    "Contribuția și beneficiile de performanță prin acord de cooperare",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea de sănătate de stat permite contribuții și oferă beneficii de performanță prin acorduri de cooperare cu diverse instituții și furnizori de servicii medicale. Aceste acorduri asigură servicii medicale de înaltă calitate și facilitează accesul la tratamente eficiente.",
                },
                {
                  type: "subtitle",
                  content:
                    "APLICAȚIE de servicii profesionale și servicii suplimentare",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea de sănătate de stat include accesul la o aplicație de servicii profesionale care oferă informații și asistență suplimentară. Aplicația permite utilizatorilor să găsească rapid și ușor servicii medicale, să programeze consultații și să acceseze resurse pentru îmbunătățirea sănătății.",
                },
                {
                  type: "note",
                  content:
                    "Prin utilizarea serviciilor incluse în asigurarea de sănătate obligatorie, poți asigura o stare de sănătate optimă pentru tine și familia ta.",
                },
                {
                  type: "subtitle",
                  content:
                    "Serviciu gratuit de a doua opinie și îndemnizație zilnică",
                },
                {
                  type: "description",
                  content:
                    "Un alt beneficiu important al asigurării de sănătate de stat este serviciul gratuit de a doua opinie medicală. Acesta oferă pacienților posibilitatea de a consulta un al doilea specialist pentru a confirma un diagnostic sau un plan de tratament. De asemenea, asigurarea include o îndemnizație zilnică pentru perioada de spitalizare sau incapacitate temporară de muncă.",
                },
                {
                  type: "subtitle",
                  content:
                    "Cooperarea cu dentiștii cu beneficii financiare pentru clienți",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea de sănătate obligatorie de stat colaborează cu dentiști și oferă beneficii financiare clienților. Aceasta include acoperirea parțială sau totală a costurilor pentru tratamente dentare esențiale, asigurând astfel accesul la îngrijire orală de calitate.",
                },
                {
                  type: "description",
                  content:
                    "Prin aceste caracteristici și beneficii, Asigurarea de Sănătate Obligatorie de Stat asigură un sistem de sănătate robust și accesibil pentru toți cetățenii, promovând prevenția și tratamentul eficient al afecțiunilor medicale.",
                },
              ],
            },
            {
              title: "Asigurare de sănătate privată",
              slug: "asigurare-de-sanatate-privata",
              data: [
                {
                  type: "subtitle",
                  content: "Ce este Asigurarea de Sănătate Privată?",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea de sănătate privată în Germania oferă o alternativă la asigurarea de sănătate obligatorie de stat. Este destinată persoanelor cu venitul brut peste plafonul obligatoriu de asigurare, oferind beneficii suplimentare și servicii medicale de calitate superioară.",
                },
                {
                  type: "note",
                  content:
                    "Asigurarea de sănătate privată este ideală pentru cei care doresc să beneficieze de servicii medicale de înaltă calitate și să aibă control asupra planului lor de sănătate.",
                },
                {
                  type: "subtitle",
                  content:
                    "Cine poate beneficia de Asigurarea de Sănătate Privată?",
                },
                {
                  type: "list",
                  content: [
                    "Angajați cu venituri mari: Cei care câștigă peste plafonul de asigurare obligatorie pot opta pentru o asigurare privată, beneficiind de servicii personalizate și acces rapid la tratamente medicale.",
                    "Antreprenori privați: Proprietarii de afaceri și liber-profesioniștii pot alege asigurarea privată pentru a beneficia de un pachet de sănătate adaptat nevoilor lor specifice.",
                    "Funcționari publici: Aceștia pot opta pentru asigurarea de sănătate privată, beneficiind de acoperire extinsă și servicii medicale de top.",
                  ],
                },
                {
                  type: "subtitle",
                  content: "Beneficiile Asigurării de Sănătate Privată",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea de sănătate privată oferă o gamă largă de beneficii care asigură o experiență medicală superioară și acces rapid la servicii de specialitate.",
                },
                {
                  type: "list",
                  content: [
                    "Acces la medici specialiști: Polițele de asigurare privată permit accesul direct la specialiști fără necesitatea unui trimitere de la medicul de familie.",
                    "Spitale și clinici private: Beneficiarii au acces la o rețea extinsă de spitale și clinici private, asigurându-se de cele mai bune condiții de tratament.",
                    "Servicii medicale personalizate: Asigurarea privată oferă planuri de tratament adaptate nevoilor individuale ale pacientului.",
                    "Timpi de așteptare reduși: Programările și tratamentele sunt accesibile mai rapid, reducând astfel timpii de așteptare.",
                  ],
                },
                {
                  type: "subtitle",
                  content: "Flexibilitate și opțiuni personalizate",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea de sănătate privată permite personalizarea polițelor în funcție de nevoile și preferințele individuale. Clienții pot alege nivelul de acoperire, includerea anumitor servicii suplimentare și accesul la tehnologii medicale avansate.",
                },
                {
                  type: "note",
                  content:
                    "Alegerea asigurării de sănătate privată poate oferi liniște sufletească și siguranță, știind că vei avea acces la cele mai bune îngrijiri medicale disponibile.",
                },
              ],
            },
            {
              title: "Asigurare dentară și protecție suplimentară",
              slug: "asigurare-dentara-si-protectie-suplimentara",
              data: [
                {
                  type: "subtitle",
                  content:
                    "Ce este Asigurarea Dentară și Protecție Suplimentară?",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea dentară și protecție suplimentară oferă o acoperire extinsă pentru tratamentele și intervențiile dentare, completând beneficiile oferite de asigurările medicale generale. Aceasta asigură protecție financiară pentru o gamă largă de proceduri dentare și contribuie la menținerea sănătății orale optime.",
                },
                {
                  type: "note",
                  content:
                    "Asigurarea dentară și protecție suplimentară este esențială pentru menținerea sănătății orale și prevenirea problemelor dentare costisitoare în viitor.",
                },
                {
                  type: "subtitle",
                  content:
                    "Ce acoperă Asigurarea Dentară și Protecție Suplimentară?",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea dentară și protecție suplimentară poate acoperi o varietate de tratamente și proceduri dentare, inclusiv:",
                },
                {
                  type: "list",
                  content: [
                    "Înlocuire dentară: Protezări, implanturi, încrustări și coroane dentare.",
                    "Umpluturi plastice: Tratamente pentru carii și alte probleme dentare.",
                    "Tratament parodontal: Intervenții pentru tratarea afecțiunilor gingivale și a altor probleme ale țesuturilor de susținere ale dinților.",
                    "Aparat dentar: În unele cazuri, asigurarea poate acoperi costurile pentru aparat dentar la copii.",
                  ],
                },
                {
                  type: "subtitle",
                  content:
                    "Beneficiile Asigurării Dentare și Protecție Suplimentare",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea dentară și protecție suplimentară oferă multiple avantaje pentru asigurații săi.",
                },
                {
                  type: "list",
                  content: [
                    "Acces la tratamente dentare de calitate: Asigurarea permite pacienților să beneficieze de intervenții dentare esențiale fără a fi nevoie să suporte costuri mari.",
                    "Prevenirea problemelor dentare: Acoperirea pentru tratamente preventive poate ajuta la prevenirea problemelor dentare majore în viitor.",
                    "Liniște financiară: Asigurarea oferă asiguraților liniștea sufletească că pot primi îngrijirea de care au nevoie fără să își pună în pericol situația financiară.",
                    "Protecție pentru întreaga familie: Asigurarea poate fi extinsă pentru a acoperi întreaga familie, asigurându-le tuturor accesul la îngrijire dentară de calitate.",
                  ],
                },
                {
                  type: "note",
                  content:
                    "Investiția într-o asigurare dentară poate aduce beneficii semnificative pe termen lung, asigurându-vă că aveți un zâmbet sănătos și o situație financiară protejată.",
                },
              ],
            },
            {
              title: "Conceptul junior",
              slug: "conceptul-junior",
              data: [
                {
                  type: "subtitle",
                  content: "Ce este Asigurarea de Sănătate: Conceptul Junior?",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea de sănătate: Conceptul Junior este o poliță special concepută pentru a oferi copiilor și tinerilor acces la îngrijire medicală de calitate și pentru a le asigura o protecție completă împotriva diverselor probleme de sănătate.",
                },
                {
                  type: "subtitle",
                  content:
                    "Ce acoperă Asigurarea de Sănătate: Conceptul Junior?",
                },
                {
                  type: "list",
                  content: [
                    "Ajutor de ochelari",
                    "80% pentru coroane, înlocuire, implanturi",
                    "80% tratamente și paradontoza",
                    "100% pentru aparat dentar",
                    "Curățări profesionale",
                    "Până la 60 de zile în concediu decontare",
                    "Tratat de șeful spitalului",
                    "Max 2 paturi în camera",
                    "Operații ambulante",
                    "Transport Acasă-Clinică, Clinică-Acasă",
                    "Alegerea spitalului",
                    "Restituirea taxelor către medici",
                  ],
                },
                {
                  type: "note",
                  content:
                    "Asigurarea de sănătate: Conceptul Junior este esențială pentru asigurarea unei îngrijiri medicale de calitate și pentru protejarea sănătății copiilor și tinerilor.",
                },
                {
                  type: "subtitle",
                  content:
                    "Beneficiile Asigurării de Sănătate: Conceptul Junior",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea de sănătate: Conceptul Junior vine cu numeroase beneficii care asigură îngrijirea completă și protecția sănătății copiilor și tinerilor.",
                },
                {
                  type: "list",
                  content: [
                    "Acces la tratamente dentare esențiale: Asigurarea acoperă costurile pentru tratamentele dentare, inclusiv coroane, implanturi și tratamente pentru paradontoza.",
                    "Acoperire extinsă pentru operații și intervenții medicale: Asigurarea asigură acoperire pentru operații ambulante, tratamente și intervenții chirurgicale necesare.",
                    "Flexibilitate în alegerea spitalului și a medicului: Asigurații au libertatea de a alege spitalul și medicul care să îi trateze, asigurându-se că primesc îngrijirea de care au nevoie.",
                    "Servicii suplimentare incluse: Polița include servicii precum transportul între acasă și clinică, curățările profesionale și tratamentul decontat în concediu.",
                  ],
                },
                {
                  type: "note",
                  content:
                    "Investiția în această asigurare oferă părinților liniștea sufletească că copiii lor vor beneficia de îngrijire medicală completă și de calitate în caz de nevoie.",
                },
              ],
            },
            // {
            //   title: "The Young and Drive package",
            //   slug: "young-drive-package",
            //   data: [
            //     {
            //       type: "subtitle",
            //       content: "This is a subtitle",
            //     },
            //     {
            //       type: "description",
            //       content: "This is a description",
            //     },
            //     {
            //       type: "list",
            //       content: ["item 1", "item 2", "item 3"],
            //     },
            //     {
            //       type: "note",
            //       content: "This is a note",
            //     },
            //     {
            //       type: "image",
            //       content: require("@/assets/images/bg-advisory.jpg"),
            //     },
            //   ],
            // },
          ],
        },
        {
          name: "Protecție",
          slug: "protectie",
          icon: require("@/assets/icons/helmet.png"),
          children: [
            {
              title: "Asigurarea de răspundere civilă privată",
              slug: "asigurarea-de-raspundere-civila",
              data: [
                {
                  type: "subtitle",
                  content: "Ce este Asigurarea de Răspundere Civilă Privată?",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea de răspundere civilă privată este o poliță care te protejează în cazul în care cauzezi daune materiale sau vătămări corporale altor persoane. Ea acoperă costurile de reparații sau compensații legale, permițându-ți să îți continui viața fără griji legate de potențialele consecințe financiare.",
                },
                {
                  type: "note",
                  content:
                    "Asigurarea de răspundere civilă privată este esențială pentru oricine deține bunuri sau desfășoară activități care ar putea să cauzeze daune altor persoane.",
                },
                {
                  type: "subtitle",
                  content:
                    "Cum te ajută Asigurarea de Răspundere Civilă Privată?",
                },
                {
                  type: "description",
                  content:
                    "Această asigurare te protejează în mai multe moduri:",
                },
                {
                  type: "list",
                  content: [
                    "Acoperă daunele materiale: Asigurarea plătește costurile reparațiilor pentru bunurile deteriorate în urma unei acțiuni din partea ta.",
                    "Protejează împotriva vătămurilor corporale: Dacă cauzezi vătămări unei alte persoane, asigurarea acoperă costurile tratamentului medical și poate compensa prejudiciul moral.",
                    "Ajută la costurile legale: Asigurarea poate acoperi cheltuielile legale, cum ar fi costurile de judecată și compensațiile acordate persoanelor vătămate.",
                  ],
                },
                {
                  type: "subtitle",
                  content:
                    "Beneficiile Asigurării de Răspundere Civilă Privată",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea de răspundere civilă privată oferă multiple beneficii pentru asigurații săi.",
                },
                {
                  type: "list",
                  content: [
                    "Liniște sufletească: Cu această asigurare, poți fi liniștit știind că ești protejat împotriva consecințelor financiare ale unor evenimente neașteptate.",
                    "Protecție financiară: Ea asigură acoperirea costurilor de reparații sau compensații, permițându-ți să eviți pierderi financiare semnificative.",
                    "Siguranță în viața de zi cu zi: Cu această protecție, poți continua să îți desfășori activitățile obișnuite fără teama că vei fi responsabil pentru eventualele daune cauzate.",
                  ],
                },
                {
                  type: "note",
                  content:
                    "Investiția în această asigurare oferă o protecție solidă împotriva riscurilor financiare asociate cu posibilele accidente sau evenimente neașteptate.",
                },
              ],
            },
            {
              title: "Asigurarea locuinței și a bunurilor din ea",
              slug: "asigurarea-locuintei-si-a-bunurilor-din-ea",
              data: [
                {
                  type: "subtitle",
                  content:
                    "Ce este Asigurarea Locuinței și a Bunurilor din Ea?",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea locuinței și a bunurilor din ea îți oferă protecție împotriva diverselor riscuri, cum ar fi incendiile, furturile sau daunele accidentale. Prin această poliță, poți asigura locuința ta și bunurile prețioase din interiorul ei, având liniștea că, în caz de pierderi neașteptate, vei primi despăgubiri pentru pagubele suferite.",
                },
                {
                  type: "subtitle",
                  content:
                    "Cum te ajută Asigurarea Locuinței și a Bunurilor din Ea?",
                },
                {
                  type: "description",
                  content:
                    "Această asigurare îți oferă o serie de beneficii importante:",
                },
                {
                  type: "list",
                  content: [
                    "Protecție împotriva riscurilor majore: Asigurarea acoperă daunele cauzate de incendii, furturi sau alte evenimente neprevăzute care pot afecta locuința ta sau bunurile din interiorul ei.",
                    "Liniște sufletească: Știind că locuința ta și bunurile prețioase sunt asigurate, poți trăi fără griji legate de posibilele pierderi financiare în caz de accident.",
                    "Repararea sau înlocuirea bunurilor deteriorate: În cazul unor pagube, poți primi despăgubiri pentru a repara sau înlocui bunurile afectate, astfel încât să poți reveni rapid la o viață normală.",
                  ],
                },
                {
                  type: "subtitle",
                  content:
                    "Beneficiile Asigurării Locuinței și a Bunurilor din Ea",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea locuinței și a bunurilor din ea vine cu multiple avantaje pentru asigurații săi.",
                },
                {
                  type: "list",
                  content: [
                    "Pace de spirit: Cu această asigurare, poți locui în locuința ta fără frica de pierderi majore și fără să îți faci griji pentru bunurile tale.",
                    "Protecție financiară: Ea asigură acoperirea financiară în caz de daune, oferindu-ți siguranța că vei putea face față costurilor reparațiilor sau înlocuirii bunurilor afectate.",
                    "Flexibilitate în alegerea acoperirii: Poți personaliza polița pentru a se potrivi nevoilor tale specifice, alegând nivelul de acoperire și adăugând clauze suplimentare în funcție de preferințele tale.",
                  ],
                },
              ],
            },
            {
              title: "Asigurarea sticlei din locuință",
              slug: "asigurarea-sticlei-din-locuinta",
              data: [
                {
                  type: "subtitle",
                  content: "Ce este Asigurarea Sticlei din Locuință?",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea sticlei din locuință oferă protecție împotriva spargerii accidentale a geamurilor, oglinzilor sau vitrinelor din locuința ta. Această poliță este esențială pentru a acoperi costurile reparațiilor sau înlocuirii acestor elemente vitale și deseori costisitoare.",
                },
                {
                  type: "note",
                  content:
                    "Cu această asigurare, nu mai trebuie să-ți faci griji! Ea îți oferă protecție în cazul spargerii accidentale a geamurilor, oglinzilor sau vitrinelor din locuință.",
                },
                {
                  type: "subtitle",
                  content: "Cum te ajută Asigurarea Sticlei din Locuință?",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea sticlei din locuință îți oferă o serie de beneficii importante:",
                },
                {
                  type: "list",
                  content: [
                    "Acoperire pentru geamuri și oglinzi: Polița acoperă costurile de reparații sau înlocuire pentru geamurile și oglinzile sparte accidental.",
                    "Protecție pentru vitrine: Dacă ai vitrine în locuință, asigurarea acoperă și acestea, asigurându-te că nu vei suporta costuri mari pentru înlocuire.",
                    "Liniște sufletească: Cu această protecție, poți fi sigur că, în cazul unui incident, asigurătorul va acoperi costurile reparațiilor necesare.",
                  ],
                },
                {
                  type: "subtitle",
                  content: "Beneficiile Asigurării Sticlei din Locuință",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea sticlei din locuință vine cu numeroase avantaje pentru asigurați.",
                },
                {
                  type: "list",
                  content: [
                    "Protecție extinsă: Asigurarea acoperă o gamă largă de elemente din sticlă din locuința ta, oferindu-ți o protecție completă.",
                    "Economii financiare: În cazul unui incident, asigurarea acoperă costurile mari de reparație sau înlocuire, protejându-te de cheltuieli neprevăzute.",
                    "Servicii rapide: Asigurătorii oferă soluții rapide și eficiente pentru reparațiile necesare, astfel încât să nu trebuiască să aștepți mult pentru a-ți reface locuința.",
                  ],
                },
                {
                  type: "note",
                  content:
                    "Poți conta pe asigurător pentru a acoperi costurile reparațiilor, asigurându-te că locuința ta rămâne în condiții optime fără eforturi financiare suplimentare din partea ta.",
                },
              ],
            },
            {
              title: "Asigurarea în caz de accident",
              slug: "asigurarea-in-caz-de-accident",
              data: [
                {
                  type: "subtitle",
                  content: "Ce este Asigurarea în Caz de Accident?",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea în caz de accident îți oferă acoperire financiară în situații neprevăzute care implică răniri sau deces în urma unui accident. Această poliță este esențială pentru a asigura protecția financiară atât pentru tine, cât și pentru familia ta, oferindu-ți sprijinul necesar în momente dificile.",
                },
                {
                  type: "note",
                  content:
                    "Cu această asigurare, beneficiezi de acoperire financiară în caz de răniri sau chiar deces în urma unui accident. Ea te protejează atât pe tine, cât și pe familia ta.",
                },
                {
                  type: "subtitle",
                  content: "Cum te ajută Asigurarea în Caz de Accident?",
                },
                {
                  type: "description",
                  content:
                    "Această asigurare îți oferă o serie de beneficii importante:",
                },
                {
                  type: "list",
                  content: [
                    "Acoperire financiară în caz de răniri: Asigurarea oferă despăgubiri pentru costurile medicale și alte cheltuieli asociate cu răniri suferite în urma unui accident.",
                    "Sprijin financiar în caz de deces: În cazul unui deces accidental, asigurarea oferă sprijin financiar familiei tale, ajutându-i să facă față pierderii și cheltuielilor neașteptate.",
                    "Protecție pentru întreaga familie: Asigurarea poate acoperi și membrii familiei tale, asigurându-te că toți cei dragi sunt protejați în caz de accident.",
                  ],
                },
                {
                  type: "subtitle",
                  content: "Beneficiile Asigurării în Caz de Accident",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea în caz de accident vine cu numeroase avantaje pentru asigurați.",
                },
                {
                  type: "list",
                  content: [
                    "Liniște sufletească: Cu această asigurare, poți fi sigur că ești protejat împotriva riscurilor financiare asociate cu accidentele neprevăzute.",
                    "Siguranță pentru cei dragi: Asigurarea oferă sprijin financiar familiei tale în caz de accident, asigurându-le stabilitatea financiară.",
                    "Acoperire extinsă: Polița poate include diverse tipuri de accidente și poate acoperi multiple situații, oferindu-ți o protecție cuprinzătoare.",
                  ],
                },
                {
                  type: "note",
                  content:
                    "Investiția în această asigurare oferă o protecție solidă împotriva riscurilor financiare asociate cu accidentele, permițându-ți să te concentrezi pe recuperare fără griji financiare.",
                },
              ],
            },
            {
              title: "Asigurarea călătoriei",
              slug: "",
              data: [
                {
                  type: "subtitle",
                  content: "Ce este Asigurarea Călătoriei?",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea călătoriei este esențială pentru călătorii pasionați. Aceasta oferă protecție împotriva riscurilor comune în timpul călătoriilor, cum ar fi pierderea bagajelor, anularea sau întârzierea zborurilor, probleme medicale și alte evenimente neașteptate.",
                },
                {
                  type: "note",
                  content:
                    "Dacă ești un călător pasionat, asigurarea călătoriei este un must-have.",
                },
                {
                  type: "subtitle",
                  content: "Cum te ajută Asigurarea Călătoriei?",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea călătoriei oferă multiple beneficii pentru a-ți asigura liniștea și siguranța pe parcursul călătoriilor tale.",
                },
                {
                  type: "list",
                  content: [
                    "Protecție împotriva pierderii bagajelor: Dacă bagajele tale se pierd sau sunt furate, asigurarea acoperă costurile pentru înlocuirea bunurilor.",
                    "Acoperire pentru anularea sau întârzierea zborurilor: Asigurarea te despăgubește în caz de anulare sau întârziere a zborurilor, ajutându-te să eviți pierderi financiare și neplăceri.",
                    "Sprijin în caz de probleme medicale: În cazul în care întâmpini probleme medicale în timpul călătoriei, asigurarea acoperă costurile tratamentului și îți oferă asistență necesară.",
                    "Protecție împotriva evenimentelor neașteptate: Asigurarea acoperă diverse situații neprevăzute care pot apărea în timpul călătoriilor, oferindu-ți siguranță și suport financiar.",
                  ],
                },
                {
                  type: "subtitle",
                  content: "Beneficiile Asigurării Călătoriei",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea călătoriei vine cu numeroase avantaje pentru călători.",
                },
                {
                  type: "list",
                  content: [
                    "Explorare în siguranță: Cu asigurarea călătoriei, poți explora lumea fără griji, știind că ești protejat împotriva riscurilor comune.",
                    "Liniște sufletească: Asigurarea îți oferă pacea sufletească necesară pentru a te bucura pe deplin de vacanță, fără a te preocupa de posibilele incidente.",
                    "Suport în caz de urgență: Indiferent de situația întâmpinată, asigurarea călătoriei îți oferă suportul necesar pentru a gestiona eficient orice problemă.",
                  ],
                },
                {
                  type: "note",
                  content:
                    "Astfel, poți explora lumea în siguranță, știind că ești protejat.",
                },
              ],
            },
            {
              title: "Asigurarea juridică ADVOCARD ",
              slug: "asigurarea-juridica-advocard",
              data: [
                {
                  type: "subtitle",
                  content: "Ce este Asigurarea Juridică ADVOCARD?",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea juridică ADVOCARD oferă protecție în patru domenii principale: privat, muncă, trafic și chirii sau proprietate personală. Această poliță te ajută să fii pregătit pentru diferite situații juridice neprevăzute și să ai sprijinul necesar în rezolvarea acestor probleme.",
                },
                {
                  type: "subtitle",
                  content: "Domenii acoperite de Asigurarea Juridică ADVOCARD",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea juridică ADVOCARD acoperă patru sectoare principale:",
                },
                {
                  type: "list",
                  content: [
                    "Sectorul privat: Include protecția împotriva hărțuirii online, problemelor cu comenzile deteriorate cumpărate online și nerespectarea clauzelor contractuale de către agențiile de turism.",
                    "Sectorul muncii: Acoperă situații precum concedierea nejustificată, nerespectarea timpului de lucru și probleme legate de documentele de angajare.",
                    "Sectorul traficului: Oferă asistență în cazul unui accident rutier cu dispute legate de vinovăție, probleme cu service-ul auto și încălcarea limitelor de viteză.",
                    "Sectorul chiriei sau proprietății personale: Include protecția împotriva creșterii nejustificate a chiriei, rezilierii nejustificate a contractului de chirie și conflictelor cu vecinii.",
                  ],
                },
                {
                  type: "subtitle",
                  content: "Beneficiile Asigurării Juridice ADVOCARD",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea juridică ADVOCARD aduce numeroase beneficii pentru asigurați.",
                },
                {
                  type: "list",
                  content: [
                    "Siguranță și liniște sufletească: Te asigură că ești pregătit pentru orice dispută juridică și ai sprijinul necesar pentru a-ți apăra drepturile.",
                    "Economii financiare: Evită costurile mari asociate cu serviciile juridice și procesele legale, deoarece asigurarea acoperă multe dintre aceste cheltuieli.",
                    "Asistență specializată: Beneficiezi de consultanță și asistență de la experți în domeniul juridic, ajutându-te să navighezi prin complexitatea sistemului legal.",
                  ],
                },
                {
                  type: "note",
                  content:
                    "Asigurarea juridică ADVOCARD îți oferă protecție și sprijin în situații juridice diverse, permițându-ți să fii pregătit pentru orice.",
                },
              ],
            },
            {
              title: "Asigurarea auto",
              slug: "asigurarea-auto",
              data: [
                {
                  type: "subtitle",
                  content: "Protecția Asigurării Auto",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea auto este esențială pentru a vă proteja vehiculul și pentru a vă asigura că sunteți pregătit pentru orice situație neașteptată pe drum. Cu protecția noastră optimă, aveți posibilitatea de a alege între diferite planuri și opțiuni de acoperire, astfel încât să puteți personaliza polița conform nevoilor și bugetului dumneavoastră.",
                },
                {
                  type: "subtitle",
                  content: "Beneficiile Asigurării Auto",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea auto vă oferă o serie de beneficii importante:",
                },
                {
                  type: "list",
                  content: [
                    "Protecție împotriva daunelor: Asigurarea acoperă costurile reparațiilor sau înlocuirii vehiculului în caz de accident, furt sau alte daune acoperite.",
                    "Acoperire pentru răspundere civilă: Polița vă protejează împotriva cererilor de despăgubire din partea altor persoane sau proprietăți în cazul în care sunteți responsabil pentru un accident.",
                    "Asistență rutieră: Multe polițe includ servicii de asistență rutieră, cum ar fi tractarea vehiculului, service-ul de drum și transportul alternativ în caz de defecțiuni sau accidente.",
                    "Asistență juridică: Unele planuri de asigurare auto oferă și asistență juridică pentru a vă ajuta în cazul unor dispute legale legate de accidente sau alte probleme asociate vehiculului dumneavoastră.",
                  ],
                },
                {
                  type: "subtitle",
                  content: "Alegerea Corectă pentru Asigurarea Auto",
                },
                {
                  type: "description",
                  content:
                    "Este important să alegeți o poliță care să se potrivească nevoilor și situației dumneavoastră unice. Întotdeauna este recomandabil să consultați un broker sau un agent de asigurări pentru a vă asigura că înțelegeți pe deplin opțiunile și acoperirea oferită de fiecare poliță înainte de a face o alegere.",
                },
                {
                  type: "note",
                  content:
                    "Indiferent de opțiunea pe care o alegeți, echipa noastră este întotdeauna profesionistă și de încredere, gata să vă ofere suportul și asistența necesară în protejarea vehiculului și în gestionarea situațiilor de urgență pe drum.",
                },
              ],
            },
            {
              title: "Asiguarea locuinței",
              slug: "asigurarea-locuintei",
              data: [
                {
                  type: "subtitle",
                  content: "Protecția Asigurării Locuinței",
                },
                {
                  type: "description",
                  content:
                    "Asigurarea locuinței este o măsură esențială pentru protejarea investiției tale și a siguranței familiei tale. Cu polița noastră de asigurare a locuinței, te poți bucura de o protecție completă și extinsă împotriva unei game variate de riscuri, de la incendii și inundații până la furturi și vandalism.",
                },
                {
                  type: "subtitle",
                  content: "Beneficiile Asigurării Locuinței",
                },
                {
                  type: "list",
                  content: [
                    "Despăgubiri nelimitate: Polița noastră oferă despăgubiri nelimitate pentru toate tipurile de calamități, asigurându-te că poți reconstrui sau repara locuința și bunurile tale în caz de daune severe.",
                    "Acoperire extinsă: Locuința și bunurile din ea sunt asigurate împotriva unui spectru larg de riscuri, inclusiv incendii, furturi, vandalism, inundații, cutremure și alte calamități naturale sau provocate de om.",
                    "Protecție pentru bunurile personale: În cazul unui incident acoperit, polița noastră poate acoperi și reparația sau înlocuirea bunurilor personale, cum ar fi mobilierul, electrocasnicele, bijuteriile și alte obiecte de valoare.",
                    "Asistență rapidă și eficientă: Echipa noastră de asigurări este disponibilă pentru a oferi asistență și suport imediat în caz de urgență, ajutându-te să gestionezi rapid și eficient situațiile de criză.",
                  ],
                },
                {
                  type: "note",
                  content:
                    "Indiferent de situația în care te afli, asigurarea locuinței îți oferă liniștea sufletească că locuința ta și bunurile tale sunt protejate în fața oricărui pericol sau daună.",
                },
              ],
            },
          ],
        },
        {
          name: "Pensie",
          slug: "pensie",
          icon: require("@/assets/icons/retirement-plan.png"),
          children: [
            {
              title: "Pensie privată",
              slug: "pensie-privata",
              data: [
                {
                  type: "subtitle",
                  content: "Ce Este Pensia Privată?",
                },
                {
                  type: "description",
                  content:
                    "Pensia privată este o modalitate eficientă și flexibilă de a-ți asigura un venit stabil și confortabil după pensionare. Este o formă de economisire pe termen lung care îți oferă control asupra modului în care economisești și investești pentru viitorul tău financiar.",
                },
                {
                  type: "subtitle",
                  content: "Beneficiile Pensiei Private",
                },
                {
                  type: "description",
                  content:
                    "Pensia privată vine cu numeroase beneficii semnificative:",
                },
                {
                  type: "list",
                  content: [
                    "Investiții Strategice: Prin intermediul pensiei private, ai oportunitatea de a investi fondurile tale într-o varietate de instrumente financiare, cum ar fi acțiuni, obligațiuni, fonduri mutuale sau alte active, pentru a obține randamente competitive pe termen lung.",
                    "Avantaje Fiscale: Pensia privată vine adesea cu avantaje fiscale semnificative, precum scutiri de impozit pe veniturile investite și deduceri fiscale, ceea ce îți permite să economisești mai mult pentru pensie.",
                    "Flexibilitate și Control: Ai controlul asupra modului în care sunt investite economiile tale și poți ajusta strategiile de investiții în funcție de schimbările din viața ta sau de condițiile pieței.",
                    "Independență Financiară: Prin pensia privată, îți poți asigura un venit stabil și independent după pensionare, ceea ce îți oferă siguranță financiară și libertate de a te bucura de viață fără a-ți face griji privind mijloacele de trai.",
                  ],
                },
                {
                  type: "description",
                  content:
                    "Investiția într-o pensie privată este o decizie importantă și strategică pentru planificarea viitorului tău financiar. Prin consultarea unui specialist în asigurări, poți identifica și personaliza planurile de pensie privată care corespund cel mai bine nevoilor, obiectivelor și situației tale financiare specifice.",
                },
                {
                  type: "note",
                  content:
                    "Planificarea financiară și investițiile responsabile în pensia privată pot contribui semnificativ la obținerea unui venit stabil și confortabil după pensionare. Asigură-te că iei decizii informate și că apelezi la sfaturi specializate înainte de a te angaja într-un plan de pensie privată.",
                },
              ],
            },
          ],
        },
      ],
      companyInsurances: [
        {
          title: "Asigurări de Sănătate",
          subtitle: "Protejați sănătatea echipelor dvs.",
          list: [
            "Tratamente medicale comprehensive",
            "Intervenții chirurgicale de calitate",
            "Acoperire pentru medicamente",
            "Terapii de recuperare incluse",
          ],
        },
        {
          title: "Asigurări de Viață",
          subtitle: "Securitate pentru cei dragi",
          list: [
            "Suport financiar pentru familii în cazuri nefericite",
            "Pilon de stabilitate în fața incertitudinilor vieții",
          ],
        },
        {
          title: "Asigurări de Răspundere Civilă",
          subtitle: "Protejați-vă afacerea împotriva riscurilor",
          list: [
            "Acoperiți daunele posibile cauzate terților de activitatea dumneavoastră",
            "Protejați firma împotriva pretențiilor costisitoare",
            "Mențineți reputația afacerii dvs.",
          ],
        },
        {
          title: "Asigurări de Proprietate",
          subtitle: "Protejați-vă activele",
          list: [
            "Acoperire pentru clădiri, echipamente și marfă",
            "Protecție împotriva furtului, distrugerii și altor evenimente neprevăzute",
            "Asigurarea continuității afacerii dvs.",
          ],
        },
        {
          title: "Asigurări de Riscuri Specifice",
          subtitle: "Soluții personalizate",
          list: [
            "Acoperire pentru riscuri financiare, de mediu sau tehnologice",
            "Soluții adaptate nevoilor unice ale fiecărei afaceri",
            "Pregătire pentru orice situație",
          ],
        },
      ],
      perks: [
        {
          icon: require("@/assets/icons/family-2.png"),
          description: "Asigură beneficii medicale pentru angajați",
        },
        {
          icon: require("@/assets/icons/task.png"),
          description:
            "Compensații în caz de deces pentru familiile angajaților",
        },
        {
          icon: require("@/assets/icons/leisure.png"),
          description: "Acoperă daune terților din greșeală",
        },
        {
          icon: require("@/assets/icons/loan.png"),
          description:
            "Protejează proprietăți de incendii și dezastre naturale",
        },
        {
          icon: require("@/assets/icons/shield-2.png"),
          description: "Asigură continuitatea afacerii în caz de incidente",
        },
        {
          icon: require("@/assets/icons/leisure.png"),
          description: "Protecție împotriva riscurilor neacoperite",
        },
        {
          icon: require("@/assets/icons/liability.png"),
          description: "Polițe personalizate pentru afacerea ta",
        },
      ],
      bonuses: [
        {
          icon: require("@/assets/icons/loan.png"),
          description: "Oferim sesiuni gratuite de consultanță în asigurări",
        },
        {
          icon: require("@/assets/icons/car-loan.png"),
          description: "Reducem tarifele pentru mai multe polițe",
        },
        {
          icon: require("@/assets/icons/health-care.png"),
          description: "Recompensăm recomandările de afaceri",
        },
        {
          icon: require("@/assets/icons/pouch.png"),
          description:
            "Oferim acces la seminarii despre gestionarea riscurilor",
        },
      ],
      basicInfo: [
        {
          description:
            "Insurance from the VSP package is concluded for a period of 5 years.",
        },
        {
          description:
            "If the 5th year has passed, the contract is automatically extended by another year until its termination is requested.",
        },
        {
          description:
            "The termination of the contract can be done 3 months before the end of each insurance year, but not earlier than 3 years.",
        },
        {
          description:
            "The customer can terminate the contract if he leaves Germany. ",
        },
        {
          description:
            "If the customer's monthly fee increases during the term of the contract, then there is a right to terminate the contract for 30 days.",
        },
        {
          description:
            "The products in the package can be purchased separately. ",
        },
        {
          description:
            "You are no longer protected if you have late payments. ",
        },
        {
          description: "Payment can be made monthly, quarterly or annually.",
        },
      ],
    };
  },
  watch: {
    $route(to, from) {
      if (to.hash) {
        this.scrollToSection();
      }
    },
  },
  mounted() {
    if (this.$route.hash) {
      this.scrollToSection();
    } else {
      window.scrollTo({ top: 0 });
    }
    // window.addEventListener("scroll", this.onScroll)
  },
  beforeDestroy() {
    // window.removeEventListener("scroll", this.onScroll)
  },
  methods: {
    scrollToSection() {
      let section = Object.keys(this.$refs).find(
        (item) => item == this.$route.hash
      );
      let offset = this.$refs[section].offsetTop;
      window.scrollTo({
        top: offset - 20,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.personal-insurance {
  padding: 96px var(--spacer-xl);
  margin: 0 auto;
  max-width: 1272px;
  @include for-mobile {
    padding: 84px var(--spacer-sm);
    width: calc(100% - 2rem);
  }

  .header {
    &::v-deep .title {
      margin: 0;
      font-size: 2.5rem;
    }
    &::v-deep .a-divider {
      margin: var(--spacer-xl) 0;
    }
    &::v-deep .description {
      margin: 0;
    }
  }

  .products {
    display: flex;
    flex-wrap: wrap;
    &__card {
      flex: 1 1 calc(33.33% - 2rem);
      margin: var(--spacer-sm);
      max-height: 16rem;
    }
  }

  .insurance-section {
    padding: var(--spacer-2xl) 0;
    @include for-mobile {
      padding: var(--spacer-xl) 0;
    }
  }
}
.companyAdvantages {
  display: flex;
  justify-content: space-between;
  margin: var(--spacer-2xl) 0;
  @include for-mobile {
    flex-wrap: wrap;
    margin: var(--spacer-xl) 0;
  }
  &__section {
    @include for-desktop {
      &:first-child {
        margin-right: var(--spacer-2xl);
      }
    }
  }
  &__cover {
    @include for-mobile {
      width: 100%;
      height: 10rem;
      flex: 1 1 100%;
      border-radius: 10px;
    }
    position: relative;
    width: 10rem;
    flex: 1 1 60%;
    border-radius: 25px;
    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.companyPerks {
  display: flex;
  flex-wrap: wrap;
  &__container {
    flex: 1 1 40%;
    padding: var(--spacer-sm) 0;
  }
  &__title {
    h4 {
      margin: 0 0 var(--spacer-sm);
      font-family: var(--font-family-secondary);
      font-weight: var(--font-bold);
      font-size: 1.5rem;
      border-bottom: 2px solid var(--c-primary);
      padding: 0 0 var(--spacer-sm);
    }
  }
  &__perk {
    flex: 1 1 calc(50% - 2rem);
    max-width: calc(50% - 2rem);
    padding: var(--spacer-lg) var(--spacer-sm);
  }
  &__sidenote {
    display: block;
    font-family: var(--font-family-secondary);
    font-size: var(--font-xs);
    color: var(--c-gray-lighten);
    padding: var(--spacer-sm);
    text-align: center;
  }
}
.companyInsurances {
  display: flex;
  flex-wrap: wrap;

  &__card {
    margin: var(--spacer-sm);
    flex: 1 1 calc(25% - 2rem - 2px);
    min-height: 10rem;
    // max-width: calc(25% - 2rem - 2px);
    height: 34rem;
  }
}
</style>
